import {Injectable} from '@angular/core';

declare let gtag: Function;

@Injectable({
  providedIn: 'root'
})
export class ShareService {

  constructor() {
  }

  share(type: string, url?: string, title?: string) {
    // @TODO Pripelji iz configa
    url = 'https://kviz.malipustolovci.si';
    title = 'PUSTOLOVSKI KVIZ';

    // @ts-ignore
    if (navigator.canShare) {
      // @ts-ignore
      navigator.share({
        url,
        title,
        // text: 'Our Pictures.',
      })
        .then(() => {
        })
        .catch((error) => {
          // console.log('Sharing failed', error)
        });
    } else {

      /*
      <!-- AddToAny BEGIN -->
<div>
<a href="https://www.addtoany.com/share#url=www.google.com&amp;title=title" target="_blank"><img src="https://static.addtoany.com/buttons/a2a.svg" width="32" height="32" style="background-color:royalblue"></a>
<a href="https://www.addtoany.com/add_to/facebook?linkurl=www.google.com&amp;linkname=title" target="_blank"><img src="https://static.addtoany.com/buttons/facebook.svg" width="32" height="32" style="background-color:royalblue"></a>
<a href="https://www.addtoany.com/add_to/twitter?linkurl=www.google.com&amp;linkname=title" target="_blank"><img src="https://static.addtoany.com/buttons/twitter.svg" width="32" height="32" style="background-color:royalblue"></a>
<a href="https://www.addtoany.com/add_to/email?linkurl=www.google.com&amp;linkname=title" target="_blank"><img src="https://static.addtoany.com/buttons/email.svg" width="32" height="32" style="background-color:royalblue"></a>
<a href="https://www.addtoany.com/add_to/whatsapp?linkurl=www.google.com&amp;linkname=title" target="_blank"><img src="https://static.addtoany.com/buttons/whatsapp.svg" width="32" height="32" style="background-color:royalblue"></a>
<a href="https://www.addtoany.com/add_to/facebook_messenger?linkurl=www.google.com&amp;linkname=title" target="_blank"><img src="https://static.addtoany.com/buttons/facebook_messenger.svg" width="32" height="32" style="background-color:royalblue"></a>
<a href="https://www.addtoany.com/add_to/telegram?linkurl=www.google.com&amp;linkname=title" target="_blank"><img src="https://static.addtoany.com/buttons/telegram.svg" width="32" height="32" style="background-color:royalblue"></a>
<a href="https://www.addtoany.com/add_to/google_gmail?linkurl=www.google.com&amp;linkname=title" target="_blank"><img src="https://static.addtoany.com/buttons/gmail.svg" width="32" height="32" style="background-color:royalblue"></a>
<a href="https://www.addtoany.com/add_to/copy_link?linkurl=www.google.com&amp;linkname=title" target="_blank"><img src="https://static.addtoany.com/buttons/link.svg" width="32" height="32" style="background-color:royalblue"></a>
<a href="https://www.addtoany.com/add_to/sms?linkurl=www.google.com&amp;linkname=title" target="_blank"><img src="https://static.addtoany.com/buttons/sms.svg" width="32" height="32" style="background-color:royalblue"></a>
<a href="https://www.addtoany.com/add_to/skype?linkurl=www.google.com&amp;linkname=title" target="_blank"><img src="https://static.addtoany.com/buttons/skype.svg" width="32" height="32" style="background-color:royalblue"></a>
<a href="https://www.addtoany.com/add_to/viber?linkurl=www.google.com&amp;linkname=title" target="_blank"><img src="https://static.addtoany.com/buttons/viber.svg" width="32" height="32" style="background-color:royalblue"></a>
</div>
<!-- AddToAny END -->
       */

      switch (type) {
        case 'fb':
          window.open(`https://www.addtoany.com/add_to/facebook?linkurl=${url}&amp;linkname=${name}`);
          break;
        case 'email':
          window.open(`https://www.addtoany.com/add_to/email?linkurl=${url}&amp;linkname=${name}`);
          break;
        case 'messenger':
          window.open(`https://www.addtoany.com/add_to/facebook_messenger?linkurl=${url}&amp;linkname=${name}`);
          break;
        case 'twitter':
          window.open(`https://www.addtoany.com/add_to/twitter?linkurl=${url}&amp;linkname=${name}`);
          break;
        case 'whatsapp':
          window.open(`https://www.addtoany.com/add_to/whatsapp?linkurl=${url}&amp;linkname=${name}`);
          break;
        case 'viber':
          window.open(`https://www.addtoany.com/add_to/viber?linkurl=${url}&amp;linkname=${name}`);
          break;
      }
    }
  }
}
